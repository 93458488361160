@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-VariableFont_wght.ttf);
}

@font-face {
    font-family: 'Lekton';
    src: url(../fonts/Lekton-Regular.ttf);
}

/*  _________________
    | Color Palette |
    |---------------|
    |    #FFFBF5    |
    |    #F7EFE5    |
    |    #C3ACD0    |
    |    #7743DB    |
    |---------------|
*/

body {
    font-family: 'Montserrat';
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

#title {
    font-size: 2.4em;
    font-weight: 900;
    margin-left: auto;
    margin-right: auto;
}

#MiscHolder {
    display: flex;
    flex-direction: column;
    gap: 1.3em;
}

#newButton {
    background-color: #C3ACD0;
    opacity: .5;
    width: 100%;
    padding: .7em;
    border-radius: 2em;
    text-align: center;
    border-style: ridge;
    border-color: #7743DB;
    font-weight: 1000;
    color: #7743DB;
    user-select: none;
    -webkit-user-select: none;       
    -moz-user-select: none;
    -ms-user-select: none;
}

#newButton:hover {
    cursor: pointer;
    transition-duration: 250ms;
    opacity: .9;
}

#nation_tax {
    background-position: calc(100% - 1.02em) center;
    background-image: url(../images/percent.png);
    background-size: 1.3em;
    background-repeat: no-repeat;
}

#province_tax {
    background-position: calc(100% - 1.02em) center;
    background-image: url(../images/percent.png);
    background-size: 1.3em;
    background-repeat: no-repeat;
}

#local_tax {
    background-position: calc(100% - 1.02em) center;
    background-image: url(../images/percent.png);
    background-size: 1.3em;
    background-repeat: no-repeat;
}

#socsecurity {
    background-position: calc(100% - 1.02em) center;
    background-image: url(../images/percent.png);
    background-size: 1.3em;
    background-repeat: no-repeat;
}

#popupholder {
    z-index: 30;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#popupbacker {
    z-index: 35;
    position: fixed;
    min-height: 100vh;
    width: 100%;
    height: inherit;
    background-color: #b4b4b4;
    opacity: .3;
}

#popup {
    z-index: 50;
    position: fixed;
    margin: auto;
    background-color: #F7EFE5;
    opacity: 1;
    width: 60%;
    height: fit-content;
    padding: 2em;
    border-radius: 2em;
    display: flex;
    flex-direction: column;
}

#displayholder {
    width: 100%;
    background-color: #FFF;
    border-radius: 2em;
    display: flex;
    flex-direction: row;
}

#canvasdisplay {
    padding-left: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    align-items: center;
    min-height: fit-content;
    width: 40%;
}

#canvasdisplay canvas {
    padding-left: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
}

#textdisplay {
    padding: 1em;
    padding-right: 2em;
    width: 60% !important;
    height: fit-content !important;
}

#datadisplay {
    width: 100%;
    display: flex;
    flex-direction: row;
}

#innerdisplayL {
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    gap: .3em;
    white-space: nowrap;
    line-height: 1.188em;
}

#innerdisplayR {
    margin-left: auto;
    font-family: 'Lekton';
    line-height: 1.188em;
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: .3em;
}

#downloadbutton {
    margin-top: 0;
}

.labeldisp {
    width: 100%;
}

.pointdisp {
    float: right;
}

.subtitle {
    font-size: 1.7em;
    font-weight: 800;
    margin-right: auto;
    margin-bottom: -.25em;
}

.misc_label {
    width: 100%;
    display: inline-flex;
    align-items: center;
}

.deleteBTN {
    background-color: #C3ACD0;
    opacity: .5;
    padding: .2em;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: .6em;
    text-align: center;
    border-style: ridge;
    border-color: #7743DB;
    font-weight: 1000;
    color: #7743DB;
    user-select: none;
    margin-left: auto;
    -webkit-user-select: none;       
    -moz-user-select: none;
    -ms-user-select: none;
}

.deleteBTN:hover {
    cursor: pointer;
    transition-duration: 250ms;
    opacity: .9;
}

.Button {
    background-color: #C3ACD0;
    opacity: .5;
    width: 100%;
    padding: .7em;
    margin-top: 1em;
    margin-bottom: .3em;
    border-radius: 2em;
    text-align: center;
    border-style: ridge;
    border-color: #7743DB;
    font-weight: 1000;
    color: #7743DB;
    user-select: none;
    -webkit-user-select: none;       
    -moz-user-select: none;
    -ms-user-select: none;
}

.Button:hover {
    cursor: pointer;
    transition-duration: 250ms;
    opacity: .9;
}

.creditR {
    margin-left: auto;
}

.creditL {
    margin-right: auto;
}

.Selector {
    background-color: #FFF;
    border-style: none;
    width: 100%;
    border-radius: 2em;
    padding: .6em;
    margin-top: .5em;
    margin-bottom: .3em;
    font-size: 1em;
    font-family: 'Montserrat';
}

.Input {
    width: 100%;
    border-radius: 2em;
    padding: .7em;
    margin-top: .5em;
    margin-bottom: .3em;
    font-size: 1em;
    border-style: none;
    font-family: 'Montserrat';
    background-position: calc(100% - 1.02em) center;
    padding-right: 3em !important;
    background-image: url(../images/dollar.png);
    background-size: 1.3em;
    background-repeat: no-repeat;
}

.percent {
    background-image: url(../images/percent.png);
}

.Input2 {
    width: 100%;
    border-radius: 2em;
    padding: .7em;
    margin-top: .5em;
    margin-bottom: .3em;
    font-size: 1em;
    border-style: none;
    font-family: 'Montserrat';
}

.link {
    color: #7743DB;
    text-decoration: none;
    font-weight: bold;
}

.link:hover {
    transition-duration: 150ms;
    color: #C3ACD0;
}

/* Modifications for mobile and portrait experience */
@media (orientation:portrait) {

    #displayholder {
        flex-direction: column-reverse;
    }

    #textdisplay {
        width: 100% !important;
        padding-right: 1em;
        padding-bottom: 0;
    }

    #canvasdisplay {
        padding: 0;
        padding-right: 1em;
        width: 100%;
        flex-direction: column;
        align-self: center;
    }

    #popup {
        width: 80%;
    }
    
    .CreditBox {
        flex-direction: column-reverse;
    }

    .creditR {
        margin: auto;
        margin-bottom: 1.3em;
    }
    
    .creditL {
        margin: auto;
    }
    
}

/* Modifications for mobile and small screen experience */
@media screen and (-webkit-min-device-pixel-ratio: 2) {

    #textdisplay {
        font-size: .9em;
    }

    #canvasdisplay {
        padding-left: 1em;
        padding-right: 2em;
        width: 100%;
    }
}
@media screen and (max-width: 615px) {

    #textdisplay {
        font-size: .9em;
    }

    #canvasdisplay {
        padding-left: 1em;
        padding-right: 2em;
        width: 100%;
    }
}