.Background {
    background: url(images/paper.jpg); /* kues1 via Freepik (https://www.freepik.com/free-photo/white-paper-texture_1034616.htm) */
    background-repeat: repeat;
    background-size: 100vw;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    height: fit-content;
    display: flex;
    justify-content: center;
}

.CentrePanel {
    background-color: #F7EFE5;
    border-radius: 2em;
    margin-top: 5vh;
    margin-bottom: 2.5vh;
    height: fit-content;
    padding: 2em;
    min-height: 5vh;
    min-width: fit-content;
    width: 60%;
    box-shadow: 0em 0em .4em #DDD8D2;
    display: flex;
    flex-direction: column;
    gap: 1.3em;
}

.CreditBox {
    width: 100%;
    display: flex;
}

#copyright {
    width: 100%;
    text-align: center;
}

/* Modifications for mobile and small screen experience */
@media screen and (-webkit-min-device-pixel-ratio: 2) {

    .CentrePanel {
        min-width: 100vw;
        max-width: 100vw !important;
    }

    #copyright {
        font-size: .8em;
    }
}
@media screen and (max-width: 615px) {

    .CentrePanel {
        min-width: 100vw;
        max-width: 100vw !important;
    }
}